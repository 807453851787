import React from "react";
import "./Header.css";
import logo from "../../Assets/grok/logo.png";
import { Link } from "react-router-dom";
function Header() {
  return (
    <div className="headerWrapper">
      <div className="header">
        <Link to={"/"} style={{ cursor: "pointer" }}>
          <img src={logo} alt="" />
        </Link>
      </div>
    </div>
  );
}

export default Header;
