/* eslint-disable no-sequences */
import React from "react";
import "./Home.css";

function Home() {
  return (
    <div className="homeWrapper">
      {/* <canvas className="banner_canvas" id="canvas_banner"></canvas> */}
      <div className="grok-hero">
        <h1>
          Introducing Grok-1 Coin <br />
          the engine powering Grok
        </h1>
      </div>
      <div className="home" style={{ zIndex: 1, pointerEvents: "none" }}>
        <div className="left-home">
          <h1>What is Grok-1?</h1>
          <p>
            The engine powering Grok is Grok-1, our frontier LLM, which we
            developed over the last four months. Grok-1 has gone through many
            iterations over this span of time. After announcing xAI, we trained
            a prototype LLM (Grok-0) with 33 billion parameters. <br />
            <br />
            This early model approaches LLaMA 2 (70B) capabilities on standard
            LM benchmarks but uses only half of its training resources. In the
            last two months, we have made significant improvements in reasoning
            and coding capabilities leading up to Grok-1, a state-of-the-art
            language model that is significantly more powerful, achieving 63.2%
            on the HumanEval coding task and 73% on MMLU. <br />
            <br />
            To understand the capability improvements we made with Grok-1, we
            have conducted a series of evaluations using a few standard machine
            learning benchmarks designed to measure math and reasoning
            abilities.
          </p>
          <a
            href="https://x.ai"
            target="_blank"
            className="desktop-display order-page-display"
            rel="noreferrer"
          >
            LEARN MORE
          </a>
        </div>

        <div id="widget" className="right-home">
          <iframe
            title="grok"
            src="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x66b7c5264F9B44e673ae660b81A846c4d709051C"
            frameBorder="0"
          ></iframe>
        </div>
      </div>
      {/* <Featured /> */}
    </div>
  );
}

export default Home;
