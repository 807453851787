import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  warningMessage: 1,
  pageLoading: false,
  isAnonymousGlobal: true,
};

// actions
export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    setWarningMessage: (state, action) => {
      state.warningMessage = action.payload;
    },
    setPageLoading: (state, action) => {
      state.pageLoading = action.payload;
    },
    setIsAnonymousGlobal: (state, action) => {
      state.isAnonymousGlobal = action.payload;
    },
  },
});

export const { setWarningMessage, setPageLoading, setIsAnonymousGlobal } =
  messageSlice.actions;

// selectors
export const selectWarningMessage = (state) =>
  state.warningMessage.warningMessage;
export const selectPageLoading = (state) => state.warningMessage.pageLoading;
export const selectIsAnonymousGlobal = (state) =>
  state.warningMessage.isAnonymousGlobal;

export default messageSlice.reducer;
