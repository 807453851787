import React from "react";
import styled from "styled-components";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import x from "../../Assets/x.svg";
import dextools from "../../Assets/dextools.svg";
import sticker from "../../Assets/sticker.webp";

function Footer() {
  const year = new Date().getFullYear();
  return (
    <div className="footerWrapper">
      <div className="footer">
        <div className="footerSection">
          <img src={sticker} alt="" />
          <div className="footer-right">
            <h1 style={{ color: "white", marginTop: "80px" }}>
              Join Our Community!
            </h1>
            <div>
              <GitBookIcon
                src={x}
                alt="x"
                onClick={() =>
                  window.open("https://twitter.com/Grok1ERC/", "_blank")
                }
              />
              <SocialIcons
                icon={faTelegramPlane}
                onClick={() => window.open("https://t.me/Grok1eth", "_blank")}
              />
              <GitBookIcon
                src={dextools}
                alt="dextools"
                onClick={() =>
                  window.open(
                    "https://www.dextools.io/app/en/ether/pair-explorer/0x7e01489751219b0dd2548d45a19f7797bc03c5ec/",
                    "_blank"
                  )
                }
              />
            </div>
            <div className="pp-tou row">
              <span>CA:</span>
              <span>0x66b7c5264f9b44e673ae660b81a846c4d709051c</span>
            </div>
          </div>
        </div>

        <p>Copyright ® {year} Grok-1. All Rights Reserved</p>
        <h4
          style={{
            color: "white",
            fontWeight: "400",
            textAlign: "center",
            maxWidth: "800px",
          }}
        >
          Grok-1 is not an investment and created as a meme parody token like
          doge. It has no intrinsic value or expectations of financial return.
          Grok-1 makes no promises and is not responsible for any losses or
          errors. Use at your own risk. Grok-1 Coin is not affiliated with X.
        </h4>
      </div>
    </div>
  );
}

export default Footer;

const SocialIcons = styled(FontAwesomeIcon)`
  color: white;
  width: 38px;
  height: 38px;
  margin: 0 12px;
  cursor: pointer;
  &:hover {
    scale: 1.05;
    filter: drop-shadow(5px 5px 1px #fff3);
  }
`;
const GitBookIcon = styled.img`
  margin: 0 12px -3px;
  cursor: pointer;
  &:hover {
    scale: 1.05;
    filter: drop-shadow(5px 5px 1px #fff3);
  }
`;
